import React, { Component } from "react";
import { Card, Button } from "react-bootstrap";
import { Zoom } from "react-awesome-reveal";
import { Link } from "react-router-dom";

import Loading from "./loading";
import { Text } from "./language";

export class Game extends Component {
    render() {
        if (!this.props.data) {
            return <Loading />;
        }

        const { name, icon, text, link } = this.props.data;

        return (
            <Card key={name} className="focus">
                <Zoom triggerOnce delay={this.props.index * 300}>
                    <Link to={link} style={{ textDecoration: "none" }}>
                        <Card.Img src={icon} alt={name} />
                    </Link>
                    <Card.Body>
                        <Card.Title>
                            <Text tid={name} />
                        </Card.Title>
                        <Card.Text>
                            <Text tid={text} html={true} />
                        </Card.Text>
                        <Button href={link}>
                            <Text tid="SeeMore" />
                        </Button>
                    </Card.Body>
                </Zoom>
            </Card>
        );
    }
}

export default Game;
