import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Slide, Zoom } from "react-awesome-reveal";

import Games from "./components/games";
import Contact from "./components/contact";
import About from "./components/about";
import Privacy from "./components/privacy";
import NotFound from "./components/notfound";
import Footer from "./components/footer";
import GameDetails from "./components/gamedetails";

import { Text, LanguageContext } from "./components/language";

import sitedata from "./data/sitedata.json";

export class Routes extends Component {
    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        this.state = {
            sitedata: {},
            languageSet: false,
        };
    }

    getPageData() {
        this.setState({ sitedata: sitedata });
    }

    componentDidMount() {
        this.getPageData();
    }

    render() {
        // Called with queryString parameter?
        if (!this.state.languageSet) {
            this.state.languageSet = true;

            const searchParams = new URLSearchParams(document.location.search);

            const lang = searchParams.get("lang");

            if (lang !== null && lang != undefined && lang !== "") {
                const languageContext = this.context;

                if (lang == "en" || lang === "es" || lang === "fr") {
                    languageContext.userLanguageChange(lang);
                } else {
                    languageContext.userLanguageChange("en");
                }
            }
        }

        const { sitedata } = this.state;
        const isLoncarGames = this.props.isLoncarGames;

        return (
            <Switch>
                <Route
                    exact
                    path={["/", "/index.php", "/index-es.php", "/index-fr.php"]}
                >
                    <hr id="games" className="anchorHR" />
                    <div>
                        <div className="section-title">
                            <h2>
                                <Text tid="Games" />
                            </h2>
                        </div>
                        <Zoom triggerOnce>
                            <Games
                                data={sitedata.Games}
                                isLoncarGames={isLoncarGames}
                            />
                        </Zoom>
                    </div>
                    <hr id="contact" className="anchorHR" />
                    <div>
                        <div className="section-title">
                            <h2>
                                <Text tid="Contact" />
                            </h2>
                        </div>
                        <Slide triggerOnce direction="right">
                            <Contact
                                data={sitedata.Contact}
                                isLoncarGames={isLoncarGames}
                            />
                        </Slide>
                    </div>
                    <hr id="about" className="anchorHR" />
                    <div>
                        <div className="section-title">
                            <h2>
                                <Text tid="AboutUs" />
                            </h2>
                        </div>
                        <Slide triggerOnce>
                            <About
                                data={sitedata.Site}
                                isLoncarGames={isLoncarGames}
                            />
                        </Slide>
                    </div>
                    <hr />
                    <Footer
                        data={sitedata.Site}
                        isLoncarGames={isLoncarGames}
                    />
                </Route>
                <Route
                    exact
                    path={[
                        "/privacy",
                        "/privacy.php",
                        "/privacy-es.php",
                        "/privacy-fr.php",
                    ]}
                >
                    <Privacy
                        data={sitedata.Privacy}
                        isLoncarGames={isLoncarGames}
                    />
                </Route>
                <Route
                    path="/game/:id"
                    render={(props) => (
                        <GameDetails
                            {...props}
                            games={sitedata.Games}
                            isLoncarGames={isLoncarGames}
                        />
                    )}
                />
                <Route>
                    <NotFound />
                </Route>
            </Switch>
        );
    }
}

export default Routes;
