import React, { Component } from "react";
import { CardGroup } from "react-bootstrap";

import { Text } from "./language";
import Loading from "./loading";
import Game from "./game";

export class Games extends Component {
    render() {
        if (!this.props.data) {
            return <Loading />;
        }

        return (
            <div className="card-deck">
                {this.props.data.map((d, i) => {
                    return <Game data={d} index={i} key={i} />;
                })}
            </div>
        );
    }
}

export default Games;
