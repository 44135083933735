import React, { Component } from "react";
import { Navbar, Nav } from "react-bootstrap";

import LanguageSelector from "./languageselector";
import { Text } from "./language";

export class Navigation extends Component {
    render() {
        return (
            <Navbar
                collapseOnSelect
                expand="lg"
                bg="primary"
                variant="dark"
                sticky="top"
            >
                <Navbar.Brand
                    href="#games"
                    className="d-none d-lg-inline-block"
                >
                    <img className="logo" src="/Puzzle.png" alt="Logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Brand
                    href="#games"
                    className="d-lg-none text-right"
                    style={{ display: "contents" }}
                >
                    <img className="logo" src="/Puzzle.png" alt="Logo" />
                </Navbar.Brand>
                <Navbar.Collapse
                    id="responsive-navbar-nav"
                    className="justify-content-end"
                >
                    <Nav>
                        <Nav.Link href="/#games" style={{ paddingRight: 22 }}>
                            <Text tid="Games" />
                        </Nav.Link>
                        <Nav.Link href="/#contact" style={{ paddingRight: 22 }}>
                            <Text tid="Contact" />
                        </Nav.Link>
                        <Nav.Link href="/#about" style={{ paddingRight: 22 }}>
                            <Text tid="AboutUs" />
                        </Nav.Link>
                    </Nav>
                    <Nav>
                        <LanguageSelector />
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

export default Navigation;
