import React, { Component } from "react";
import { Card } from "react-bootstrap";

import Loading from "./loading";
import { Text } from "./language";

export class About extends Component {
    render() {
        if (!this.props.data) {
            return <Loading />;
        }

        return (
            <Card>
                <Card.Body>
                    <img
                        src={this.props.data.icon}
                        alt="Loncar Logo"
                        className="mb-4 mw-100"
                    />
                    <p>
                        <Text tid="Loncar.About" html={true} />
                    </p>
                </Card.Body>
            </Card>
        );
    }
}

export default About;
