import React, { Component } from "react";
import { Container } from "react-bootstrap";
import MetaTags from "react-meta-tags";

// import ReactGA from 'react-ga';
import ParticlesBg from "particles-bg";

import Routes from "./routes";

import Navigation from "./components/navigation";
import CookieConsentExt from "./components/cookieconsentext";
import { LanguageProvider } from "./components/language";

import "./App.scss";

export class App extends Component {
    render() {
        const hostname = window.location.hostname;
        const isLoncarGames = true;
        //hostname.endsWith("loncargames.com") ||
        //hostname.startsWith("games.loncartechnologies.com");

        const title = "Loncar Games";

        return (
            <div>
                <LanguageProvider>
                    <MetaTags>
                        <title>{title}</title>
                        <meta property="og:title" content={title} />
                    </MetaTags>
                    <div id="home">
                        <div
                            style={{
                                position: "fixed",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                            }}
                        >
                            <ParticlesBg type={"circle"} bg={false} />
                        </div>
                        <Container style={{ background: "#A0A0A0" }}>
                            <Navigation />
                            <Routes isLoncarGames={isLoncarGames} />
                            <CookieConsentExt />
                        </Container>
                    </div>
                </LanguageProvider>
            </div>
        );
    }
}

export default App;
