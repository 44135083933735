import React, { Component } from "react";
import { Card, Alert, Button, Container, Row, Col } from "react-bootstrap";
import { Slide, Flip } from "react-awesome-reveal";

import Loading from "./loading";
import { Text, LanguageContext } from "./language";
import Screenshots from "./screenshots";

export class GameDetails extends Component {
    // Adding language context reference here
    static contextType = LanguageContext;

    render() {
        if (!this.props.games || !this.props.match) {
            return <Loading />;
        }

        const { match, games } = this.props;

        const game = games.find((g) => g.id === match.params.id);
        if (!game) {
            return (
                <Alert key="error" variant="danger">
                    Game not found
                </Alert>
            );
        }

        const languageContext = this.context;
        const language = languageContext.userLanguage;

        const {
            name,
            iconwide,
            text,
            linkApple,
            linkGoogle,
            linkAmazon,
            images,
        } = game;

        const linkAppleUrl = languageContext.dictionary[linkApple];
        const imgApple =
            language === "es"
                ? "/img/App_Store-ES.png"
                : language === "fr"
                ? "/img/App_Store-FR.png"
                : "/img/App_Store.png";

        const linkGoogleUrl = languageContext.dictionary[linkGoogle];
        const imgGoogle =
            language === "es"
                ? "/img/GoogleStore-ES.png"
                : language === "fr"
                ? "/img/GoogleStore-FR.png"
                : "/img/GoogleStore.png";

        const linkAmazonUrl = languageContext.dictionary[linkAmazon];
        const imgAmazon =
            language === "es"
                ? "/img/Amazon_Store-ES.png"
                : language === "fr"
                ? "/img/Amazon_Store-FR.png"
                : "/img/Amazon_Store.png";

        return (
            <div className="container-fluid bg-light text-dark p-2">
                <Container>
                    <Row>
                        <Col xs={12} md={8}>
                            <Slide triggerOnce direction="up">
                                <Card key={name} className="p-4">
                                    <Card.Img
                                        src={iconwide}
                                        className="w-100"
                                        alt={name}
                                    />
                                    <Card.Body>
                                        <Card.Title>
                                            <Text tid={name} />
                                        </Card.Title>
                                        <Card.Text>
                                            <Text tid={text} html={true} />
                                        </Card.Text>
                                        <div className="bg-dark">
                                            <Screenshots
                                                images={images}
                                                className="w-50"
                                            />
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Slide>
                        </Col>
                        <Col xs={12} md={4} className="mt-4">
                            <div className="text-center">
                                <h4>
                                    <Text tid="PlayNow" />
                                </h4>
                            </div>
                            <Slide triggerOnce direction="up">
                                <div className="text-center">
                                    {linkApple ? (
                                        <Button
                                            variant="link"
                                            href={linkAppleUrl}
                                            target="_blank"
                                        >
                                            <div className="focus">
                                                <img
                                                    src={imgApple}
                                                    alt="Apple"
                                                />
                                            </div>
                                        </Button>
                                    ) : (
                                        <div />
                                    )}
                                </div>
                            </Slide>
                            <Slide triggerOnce delay={200} direction="up">
                                <div className="text-center">
                                    {linkGoogle ? (
                                        <Button
                                            variant="link"
                                            href={linkGoogleUrl}
                                            target="_blank"
                                        >
                                            <div className="focus">
                                                <img
                                                    src={imgGoogle}
                                                    alt="Google"
                                                />
                                            </div>
                                        </Button>
                                    ) : (
                                        <div />
                                    )}
                                </div>
                            </Slide>
                            <Slide triggerOnce delay={400} direction="up">
                                <div className="text-center">
                                    {linkAmazon ? (
                                        <Button
                                            variant="link"
                                            href={linkAmazonUrl}
                                            target="_blank"
                                        >
                                            <div className="focus">
                                                <img
                                                    src={imgAmazon}
                                                    alt="Amazon"
                                                />
                                            </div>
                                        </Button>
                                    ) : (
                                        <div />
                                    )}
                                </div>
                            </Slide>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default GameDetails;
