import React, { Component } from "react";
import { Form, Button, Card, Alert, Spinner } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";

import { Text, LanguageContext } from "./language";
import Loading from "./loading";

export class Contact extends Component {
    // Adding language context reference here
    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        this.state = {
            name: "", // name value
            email: "", // email value
            message: "", // message value
            showSuccess: false,
            submitting: false,
            serverReply: "", // error/message reply from server
            captcha: "", // captcha value
            captchaVerified: false,
            errors: {}, // when errors, it contains message by type (name: err, email: err, message: err)
            formValidated: false,
        };

        this.handleName = this.handleName.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.handleMessage = this.handleMessage.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
        this.onCaptchaChange = this.onCaptchaChange.bind(this);
    }

    handleName = (event) => {
        let value = event.target.value;
        this.setState({
            name: value,
            serverReply: "",
            errors: {},
        });
    };

    handleEmail = (event) => {
        let value = event.target.value;
        this.setState({
            email: value,
            serverReply: "",
            errors: {},
        });
    };

    handleMessage = (event) => {
        let value = event.target.value;
        this.setState({
            message: value,
            serverReply: "",
            errors: {},
        });
    };

    onCaptchaChange = (token) => {
        //console.log("Captcha result:", token);
        this.setState({
            captchaVerified: true,
            captcha: token,
            serverReply: "",
        });
    };

    onCaptchaExpired = () => {
        this.setState({
            captchaVerified: false,
            captcha: "",
            serverReply: "",
        });
    };

    onCaptchaErrored = () => {
        this.setState({
            captchaVerified: false,
            captcha: "",
        });
    };

    handleSuccess = () => {
        this.setState({
            name: "",
            email: "",
            message: "",
            serverReply: "",
            showSuccess: true,
            submitting: false,
            captcha: "",
            captchaVerified: false,
            errors: {},
            formValidated: false,
        });
    };

    validateEmailFormatString = (text) => {
        const emailRegex =
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
        const regex = new RegExp(emailRegex);
        return regex.test(text || "");
    };

    isFormValid = () => {
        const { name, email, message } = this.state;

        const namerequired = !name;
        const emailrequired = !email;
        const messagerequired = !message;

        const errors = {
            name: namerequired ? <Text tid="Contact.NameRequired" /> : "",
            email: emailrequired ? <Text tid="Contact.EmailInvalid" /> : "",
            message: messagerequired ? (
                <Text tid="Contact.MessageRequired" />
            ) : (
                ""
            ),
        };

        this.setState({ errors: errors });

        return !(namerequired || emailrequired || messagerequired);
    };

    handleSubmit = (event) => {
        event.preventDefault();

        // Validate form
        if (!this.isFormValid()) {
            return;
        }

        // Validate captcha
        if (!this.state.captchaVerified) {
            this.setState({
                serverReply: <Text tid="Contact.VerifyCaptcha" />,
            });
            return;
        }

        // Build and post message
        this.setState({
            submitting: true,
            serverReply: "",
            formValidated: true,
            errors: {},
        });

        const payload = {
            name: this.state.name,
            email: this.state.email,
            message: this.state.message,
            captcha: this.state.captcha,
            domain: window.location.hostname,
        };

        const url = this.props.data.url;

        fetch(url, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(payload),
        })
            .then(async (response) => {
                const data = await response.json();

                //console.log(data);

                // check for error response
                if (!data.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                } else {
                    this.handleSuccess();
                }
            })
            .catch((error) => {
                this.setState({ serverReply: "" + error, submitting: false });
            });
    };

    render() {
        if (!this.props.data) {
            return <Loading />;
        }

        if (this.state.showSuccess) {
            return (
                <Card>
                    <Card.Body>
                        <Card.Title>
                            <b>
                                <Text tid="Contact.Header" />
                            </b>
                        </Card.Title>
                        <Alert id="success" variant="success">
                            <Text tid="Contact.Thankyou" html={true} />
                        </Alert>
                    </Card.Body>
                </Card>
            );
        }

        // Using language context here
        const languageContext = this.context;
        const language = languageContext.userLanguage;
        const namePlaceholder =
            languageContext.dictionary["Contact.NamePlaceholder"];
        const emailPlaceholder =
            languageContext.dictionary["Contact.EmailPlaceholder"];
        const messagePlaceholder =
            languageContext.dictionary["Contact.MessagePlaceholder"];
        const captchaSiteKey = this.props.isLoncarGames
            ? "6Le4aawUAAAAAMMzttgMEAqqov2OenL2fQp5Mw0z"
            : "6LffZ6wUAAAAACW5yyATFxSRAz5KQQ3JCibc7xuD";

        const { name, email, message, errors, serverReply, submitting } =
            this.state;

        return (
            <Card>
                <Card.Body>
                    <Card.Title>
                        <b>
                            <Text tid="Contact.Header" />
                        </b>
                    </Card.Title>
                    <p>
                        <Text tid="Contact.Intro" />
                    </p>
                    <Form
                        className="form"
                        onSubmit={this.handleSubmit}
                        validated={this.state.formValidated}
                        noValidate
                    >
                        <Form.Group>
                            <Form.Label>
                                <Text tid="Contact.Name" />
                            </Form.Label>
                            <Form.Control
                                id="nameText"
                                type="text"
                                name="name"
                                value={name}
                                placeholder={namePlaceholder}
                                onChange={this.handleName}
                                isInvalid={errors.name}
                                isValid={name}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.name}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <Text tid="Contact.Email" />
                            </Form.Label>
                            <Form.Control
                                id="emailText"
                                type="email"
                                name="email"
                                value={email}
                                placeholder={emailPlaceholder}
                                onChange={this.handleEmail}
                                isInvalid={errors.email}
                                isValid={email}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.email}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <Text tid="Contact.Message" />
                            </Form.Label>
                            <Form.Control
                                id="messageText"
                                type="text"
                                as="textarea"
                                rows={5}
                                name="message"
                                value={message}
                                placeholder={messagePlaceholder}
                                onChange={this.handleMessage}
                                isInvalid={errors.message}
                                isValid={message}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <label htmlFor="g-recaptcha-response">
                                <Text tid="Contact.Confirm" />
                            </label>{" "}
                            <ReCAPTCHA
                                sitekey={captchaSiteKey}
                                onChange={this.onCaptchaChange}
                                onExpired={this.onCaptchaExpired}
                                onErrored={this.onCaptchaErrored}
                                hl={language}
                                key={"reCaptcha" + language}
                            />
                        </Form.Group>
                        {serverReply !== "" ? (
                            <Alert key="error" variant="danger">
                                {serverReply}
                            </Alert>
                        ) : null}
                        {submitting ? (
                            <Button disabled>
                                <span>
                                    <Text tid="Contact.Sending" />
                                </span>
                                {"  "}
                                <Spinner animation="border" size="sm">
                                    <span className="sr-only">
                                        <Text tid="Contact.Sending" />
                                        {"..."}
                                    </span>
                                </Spinner>
                            </Button>
                        ) : (
                            <Button type="submit" style={{ marginTop: 20 }}>
                                <span>
                                    <Text tid="Contact.Submit" />
                                </span>
                            </Button>
                        )}
                    </Form>
                </Card.Body>
            </Card>
        );
    }
}

export default Contact;
