import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";

class Screenshots extends Component {
    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);
        this.state = {
            index: 0,
        };
    }
    handleSelect(selectedIndex, e) {
        this.setState({
            index: selectedIndex,
        });
    }
    render() {
        const { images } = this.props;
        const { index, direction } = this.state;

        return (
            <Carousel
                activeIndex={index}
                direction={direction}
                onSelect={this.handleSelect}
                className="ml-8"
                nextLabel={null}
                prevLabel={null}
            >
                {images.map((image, index) => {
                    return (
                        <Carousel.Item key={index}>
                            <img
                                className="d-block mx-auto w-75"
                                src={image.path}
                                alt={image.path}
                            />
                        </Carousel.Item>
                    );
                })}
            </Carousel>
        );
    }
}

export default Screenshots;
